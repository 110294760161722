<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='payIn'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add New Receipt Voucher</h5>
                        <a class="close"  @click="$store.dispatch('modalClose')">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="submit" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
                        <div class="modal-body row">
                            <div class="col-md-6 pd-r-7-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group  mb-3">
                                            <label class="group-label">Basic Information</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Branch *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select v-model="formData.branch" class="form-control" required @change="getPaymentMethods()" :disabled="branchDisabled">
                                                            <option value="">Select Branch</option>
                                                            <option v-for="(branch,index) in dataLists9.branches" :value="branch.id" :key="index">{{branch.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Receipt No</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input class="form-control" placeholder="PAYIN005" v-model="formData.receipt_no" disabled/>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Date *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="date" v-model="formData.date" class="form-control" required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group  mb-3">
                                            <label class="group-label">Account Information</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Account Type *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select v-model="formData.account_type" class="form-control" required>
                                                            <option :value="formData.account_type">	Customer</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Account Name*</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select v-model="formData.account_name" class="form-control" required>
                                                            <option :value="details.id">{{details.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group mb-3">
                                            <label class="group-label">Payee Detail</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Payee Name </div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="input" v-model="formData.payee_name" placeholder="Name" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Mobile No </div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="input" v-model="formData.phone_no" placeholder="98XXXXXXXX" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <label class="group-label">Settings</label>
                                            <div class="group-attribute-container">
                                                <div class="from-group-check">
                                                    <input class="group-check-box" id="payment_type" type="checkbox" v-model="formData.type"
                                                             value="0">
                                                    <label for="payment_type" class="ml-2">Advance Payment</label>
                                                </div>
                                                <div class="group-note"><span>Note: </span> Please Check above if received payment is Advance</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pd-l-7-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group mb-3">
                                            <label class="group-label">Amount Detail</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Total Due </div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="text" v-model="formData.total_due" class="form-control"
                                                                placeholder="NRP 0.00" disabled>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Paid Amount *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="number" min="1" v-model="formData.paid_amount" class="form-control"
                                                                placeholder="NRP 0.00" required>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Remaining Amt.</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="text" v-model="formData.due_amount" class="form-control"
                                                                placeholder="NRP 0.00" disabled>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group mb-3">
                                            <label class="group-label">Payment Type</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select @change.prevent="getBanks" v-model="formData.payment_method" class="form-control" required>
                                                            <option value="" disabled>Select Method</option>
                                                            <option v-for="(method,index) in paymentMethods" :value="method.id" :key="index">{{method.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select v-model="formData.bank" class="form-control" required :disabled="disabled">
                                                            <option value="" disabled>Select Bank</option>
                                                            <option v-for="(method,index) in banks" :value="method.id" :key="index">{{method.bank}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Transaction ID</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <input type="input" v-model="formData.transaction_id"
                                                                placeholder="1547220XD" class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group" id="description">
                                            <label class="group-label">Description</label>
                                            <div class="group-attribute-container">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                    <textarea class="form-control" type="text" v-model="formData.description"
                                                                 style="height: 5.8rem;"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right mt-3">
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <ReceiptSlip/>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import ReceiptSlip from '../Account/Pay-In/receiptSlip';
export default {
    components:{
        ReceiptSlip
    },
    data(){
        return{
            banks:[],
            details:[],
            paymentMethods:[],
            disabled:true,
            disableSubmitButton:false,
            formData:{
                'branch':'',
                'receipt_no':null,
                'date':new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                'account_type':'',
                'account_name':'',
                'payee_name':null,
                'phone_no':null,
                'type':0,
                'total_due':0,
                'paid_amount':0,
                'advance_amount':0,
                'due_amount':0,
                'payment_method':'',
                'bank':null,
                'transaction_id':null,
                'description':null
            },
            branchDisabled:false,
        }
    },
    computed: {
        ...mapGetters([
            "processing",
            "eventMessage",
            "dataLists",
            "dataLists2",
            "dataLists8",
            "dataLists9",
            "dataId",
            "modalId",
            "modalMode",
            'today'
        ]),
        ...mapGetters("customers",["customerLists"]),
        ...mapGetters("payins",["payInReceiptData"]),
    },
    mounted() {
        this.setDate();
        this.getData();
    },
    methods:{
        submit(){
          if (this.formData.paid_amount>0) {
              this.disableSubmitButton=true;
             Services.createNewPayIn(this.formData).then(res=>{
                    this.$store.commit("payins/setPayInReceiptData",res.data.data.data);
                    this.$store.commit("setDataId", res.data.data.id);
                    this.$store.dispatch("modalWithMode", { id: 'afterCreateSlip',mode:"read"});
                    this.$emit('parent-event');
                    //this.$store.dispatch("modalClose");
                    //this.setNotification(res.data.success_message);
                    this.disableSubmitButton = false;
                }).catch(err=>{
                    if(err.response.status == 422){
                        let error = Object.values(err.response.data.errors).flat();
                        this.setNotification(error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        this.setNotification(error);
                    }
                    this.disableSubmitButton = false;
                })
          }else{
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage", "Paid Amount cannot be 0");
          }
        },
        getReceiptNoByBranch(){
            if(this.formData.branch){
                Services.getReceiptNoByBranch(this.formData.branch).then(res=>{
                        this.formData.receipt_no = res.data.data;
                }).catch(err=>{     
                    console.log(err);
                })
            }
        },
        setNotification(msg){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",msg);
        },
        setDate(){
            this.formData.date=this.today;
        }, getData(){
         
          //this.$store.commit("getData8", `api/account/pay-in/type/${this.formData.account_type}/id/${this.dataId}`);
        },
        getPaymentMethods(){
            this.getReceiptNoByBranch();
            this.formData.bank = "";
            this.disabled = true;
            this.formData.account_type = this.dataLists9.customer_account_id;
            if(this.dataLists9.payment_methods.length != 0){
                let details=[];
                let methods = this.formData.branch;
                    details = this.dataLists9.payment_methods.filter(function (data) {
                        return data.branch == methods;
                    });
                this.paymentMethods= {...details};
                this.formData.payment_method = this.paymentMethods[0].id;
            }
        },
        getBanks(){
            let payment_bank = this.formData.payment_method
            let values = this.dataLists9.payment_methods.find(function (data) {
                if(data.id == payment_bank){
                    return data.title;
                } 
            });  
            if(values.title.toLowerCase() == "bank"){
                    if(this.dataLists9.banks.length != 0){
                        this.disabled = false;
                        let details=[];
                        let methods = this.formData.branch;
                            details = this.dataLists9.banks.filter(function (data) {
                                return data.branch == methods;
                            });
                        this.banks= {...details};
                        if(details.length > 0){
                            this.formData.bank = details[0].id;
                        }else{
                            this.formData.bank ="";
                        }
                    }else{
                        this.disabled = false;
                        this.formData.bank = '';
                    }
                }
                else{
                    this.disabled = true;
                    this.formData.bank = '';
                }
        },
    },
    reset(){
         this.formData={
                'branch':'',
                'receipt_no':null,
                'date':new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                'account_type':'',
                'account_name':'',
                'payee_name':null,
                'phone_no':null,
                'type':0,
                'total_due':0,
                'paid_amount':0,
                'advance_amount':0,
                'due_amount':0,
                'payment_method':'',
                'bank':null,
                'transaction_id':null,
                'description':null
            }
    },
    // beforeMount(){
    // this.getData();
    // },
    watch:{
        eventMessage(value){
            if(value.indexOf("success") >= 0){
                this.$store.dispatch('modalClose');
            }
        }, 
        modalId() {
            if(this.modalId == 'payIn'){
                Services.getPayInElements().then(res=>{
                        this.$store.commit("setDataLists9", res.data.data);
                    let id = this.dataId;
                    this.details = this.customerLists.find(function (data) {
                        return data.id == id;
                    });
                    this.formData.branch = '';
                    if(res.data.data.branches.length == 1){
                        this.formData.branch =res.data.data.branches[0].id;
                        this.branchDisabled = true;
                        this.getPaymentMethods();
                    }
                    this.paid_amount = 0;
                    if (this.details){
                    this.formData.account_name=this.details.id;
                    this.formData.total_due = this.parseDigit(this.details.closing_due);  
                    this.formData.due_amount = this.parseDigit(this.details.closing_due);
                    }
                }).catch(err=>{
                    console.log(err);
                });
            }
      },
      "formData.paid_amount"(val){
          if (val){
            this.formData.due_amount=this.parseDigit(parseFloat(this.formData.total_due)-parseFloat(val));
          }
      },"formData.type"(val){
            this.formData.due_amount=this.parseDigit(parseFloat(this.formData.total_due)-parseFloat(this.formData.paid_amount));
            this.formData.advance_amount = this.parseDigit(val);
      }
    }
}
</script>